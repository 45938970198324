
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #d0e3ff;
  --secondary-color: #091D3D;
  --trinary-color: #D6A85D;

  --primary-font: 'MyCustomFont';
  --secondary-font-regular: 'MySecondaryFontRegular';
  --secondary-font-bold: 'MySecondaryFontBold';
  --secondary-font-black: 'MySecondaryFontBlack';
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('./assets/fonts/Boston\ Angel\ Regular.ttf') format('truetype'); /* for .otf */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MySecondaryFontRegular';
  src: url('./assets/fonts/Sukar\ Regular.ttf') format('truetype'); /* for .ttf */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MySecondaryFontBold';
  src: url('./assets/fonts/SukarBold.ttf') format('truetype'); /* for .ttf */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MySecondaryFontBlack';
  src: url('./assets/fonts/Sukar\ Black.ttf') format('truetype'); /* for .ttf */
  font-weight: normal;
  font-style: normal;
}

@keyframes floatAnimation {
    from {
      transform: translate(-20%, -20%);
      opacity: 0.9;
    }
    to {
      transform: translate(-20%, -20%) translateY(-6px);
      opacity: 1;
    }
  }

  .marquee-container {
    width: 100%;
    overflow: hidden;
  }
  
  .marquee {
    display: flex;
    white-space: nowrap;
    animation: marquee 90s linear infinite;
  }
  
  .logo {
    width: 100px;
    height: auto;
    margin-right: 80px; /* Adjust the margin-right value to give more or less space between logos */
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  @media (max-width: 768px) {
    .logo {
      width: 60px;
      margin-right: 40px;
      
      /* Adjust other styles as needed for smaller screens */
    }
    .brands-container {
        margin-bottom: 40px;
    }
  }

  .zoom-image {
    transition: transform 0.2s ease-in-out; /* Add transition effect for smooth zoom */
  }
  
  .zoom-image:hover {
    transform: scale(1.1); /* Apply scale transformation on hover */
  }


  .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab{
    background-color: var(--secondary-color) !important;
  }

  .css-5lbw0b-MuiTypography-root, .css-ahj2mt-MuiTypography-root{
  font-family:'var(--secondary-font-regular)',sans-serif !important;


  /* home-speciality-box */
  }
  .speciality-box {
    margin-right: 80px;
    width: 100%;
    background-color: var(--primary-color);
    /* color: red !important; */
    transition: background-color ease-in-out 0.5s; /* Changed transition property */
    
  }
  
  .speciality-box:hover {
    background-color:var(--secondary-color);
    color: var(--trinary-color)!important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  .about-box{
    margin-right: 80px;
    width: 100%;
    background-color: var(--primary-color);
    /* color: red !important; */
    transition: background-color ease-in-out 0.5s; /* Changed transition property */
       
  }

  .about-box:hover {
    /* background-color:var(--secondary-color); */
    color: var(--trinary-color)!important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);    
  }
  .brush-animate {
    animation: brush-stroke 2s ease forwards; /* 2s animation for the brush stroke */
  }
  
  @keyframes brush-stroke {
    0% {
      transform: scaleX(0); /* Start the brush as hidden (width 0) */
      transform-origin: left; /* Animate from the left to right */
    }
    100% {
      transform: scaleX(1); /* Fully reveal the brush */
    }
  }
  
  
  

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .visible {
    animation: fadeIn 0.5s ease forwards;
  }
  
  .hidden {
    opacity: 0;
  }
  

.sticky{
  position: sticky;
  top: 150px;
}

.aboutusicon{
  width: 50px;
  animation: floatAnimation 3s infinite ease-in-out; /* Adjust duration and other properties as needed */
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.4));
}
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust the amount of vertical translation */
  }
  100% {
    transform: translateY(0);
  }
}

@media( max-width: 768px) {
  .sticky{
    position: sticky;
    top: 59px;
    z-index: -10;
  }
}

.css-r9uhzn{
  background-color: #372B29 !important;

}

/* terms and conditions */




/* Keyframes for fade-in and slide-up animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px); /* Start below and move upwards */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}

/* Animation timing */
.card-animation {
  animation: fadeInUp 1s ease forwards;
  opacity: 0; /* Start with hidden cards */
}

/* Stagger the appearance of the cards */
.card-animation:nth-of-type(1) {
  animation-delay: 0.1s;
}

.card-animation:nth-of-type(2) {
  animation-delay: 0.2s;
}

.card-animation:nth-of-type(3) {
  animation-delay: 0.3s;
}

.card-animation:nth-of-type(4) {
  animation-delay: 0.4s;
}

.card-animation:nth-of-type(5) {
  animation-delay: 0.5s;
}

.card-animation:nth-of-type(6) {
  animation-delay: 0.6s;
}

/* Background color styles */
.list-bg-1 {
  background-color: rgba(148, 184, 237, 0.1);
}


.list-bg-2 {
  background-color: rgba(214, 168, 93, 0.1);
}
/* .list-bg-3 {
  background-color: rgba(9, 29, 61, 0.1);
} */

/* General card styles */
.card-content {
  padding: 20px !important;
  border-radius: 10px !important;
  margin-bottom: 30px !important;
  transition: transform 0.3s ease-in-out !important; /* Slight hover effect */
  
}

.card-content:hover {
  transform: translateY(-5px) !important; /* Slight lift on hover */
}

/* Keyframe animation for the text */
@keyframes textFadeInUp {
  0% {
    opacity: 0;
    transform: translateY(-20px); /* Start below */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at the normal position */
  }
}

/* Apply the animation to the text */
.text-animation {
  animation: textFadeInUp 1s ease-out forwards !important;
}

.card-details{
  margin-left: 20px !important;
}

.css-cveggr-MuiListItemIcon-root{
  min-width: 0px !important;
}