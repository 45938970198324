

@font-face {
  font-family: 'MyCustomFont';
  src: url('./assets/fonts/Boston\ Angel\ Regular.ttf') format('truetype'); /* for .otf */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MySecondaryFontRegular';
  src: url('./assets/fonts/Sukar\ Regular.ttf') format('truetype'); /* for .ttf */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MySecondaryFontBold';
  src: url('./assets/fonts/SukarBold.ttf') format('truetype'); /* for .ttf */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MySecondaryFontBlack';
  src: url('./assets/fonts/Sukar\ Black.ttf') format('truetype'); /* for .ttf */
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'MySecondaryFontRegular, sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  font-family:'MySecondaryFontRegular',sans-serif ;

}

.MuiTypography-root {
  font-family: 'MySecondaryFontRegular', sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Satoshi-Regular';
  src: url('../src/assets/fonts/Satoshi-Regular.woff') format('woff'), 
}
@font-face {
  font-family: 'integral-Regular';
  src: url('../src/assets/fonts/IntegralCF-ExtraBold.woff') format('woff'), 
       url('../src/assets/fonts/IntegralCF-ExtraBold.woff2') format('woff2')
}




