/* @import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  width: 70%;
  margin: 1rem auto;
  height: 470px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide img {
  width: 24rem;
  margin: 0 auto;
  height: 100%;
}
.slick-slider{
  width: 100% !important;
}

.slick-list{
  width: 100% !important;

}
.slick-track{
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide {
  transform: scale(0.5);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1);
  opacity: 1;
  height: 430px;
}

.arrow {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #D6A08C;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

@media (max-width: 640px) {  
  /* For devices 
    .App {
        width: 100%;
        margin: 1rem auto;
        padding: 0 20px;
        height: 250px;
      }
      
      .slide img {
        width: 100%;
        margin: 0 ;
      }
      
      .slide {
        transform: scale(0.4);
        transition: transform 300ms;
        opacity: 0.5;
        z-index: -1;
      }
      
      .slick-track{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .activeSlide {
        transform: scale(1.0);
        opacity: 1;
        z-index: 1;
        height: 100%;
        width: 100%;
      }
      .slick-current{
        width: 285px !important;
        height: 100% !important;
      }
      
      
} */





@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  width: 70%;
  margin: 1rem auto;
  height: 470px;
  margin-bottom:50px;
}

.slide img {
  width: 24rem;
  margin: 0 auto;
  height:100%;
}

.slide {
  transform: scale(0.5);
  transition: transform 300ms;
  opacity: 0.5;
  margin:40px 0;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
  margin:40px 0;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #D6A08C;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}
.side{
  display:block;
}

@media (max-width: 640px) {  /* For devices */
    .App {
        width: 90%;
        margin: 1rem auto;
        margin-bottom: 0;
        height: 360px;
      }
      
      .slide img {
        width: 100%;
        margin: -80px 0.5px;
        height:100%
      }
      
      .slide {
        transform: scale(0.2);
        transition: transform 300ms;
        opacity: 0.5;
        z-index: -1;
        margin:55px 0;
      }
      
      .activeSlide {
        transform: scale(0.8);
        opacity: 1;
        z-index: 1;
        height: 100%;
        width: 100%;
        margin: 50px 0;
      }
    
}