
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  display: block;
}
/* Testimonial container */
.testimonial-container {
  max-width: 1100px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 40vh; */
  margin-bottom:80px; 
}

.title {
  text-align: center;
  margin-bottom: 2rem;
  margin-top:60px;
}

.title h2 {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 1.1;
}

/* Slider container */
.slider-container {
  position: relative;
  user-select: none;
  padding-inline: 1rem;
  height: 40vh;
  margin-bottom: 50px;
  
}

.slider-container .quote {
  position: absolute;
  z-index: -1;
}

.slider-container .top-quote {
  position: absolute;
  top: -3rem;
  left: -1.8rem;
}

.slider-container .bottom-quote {
  position: absolute;
  bottom: -3rem;
  right: -0.5rem;
  transform: rotate(180deg);
}

.slider-container .review-img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  margin-inline: auto;
}

/* Splide customization */
.splide__track {
  border-radius: 1rem;
  margin-bottom:250px;
}

.splide__slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1); /* Transparent glass-like background */
  padding: 2rem 1.5rem;
  border-radius: 1rem;
  gap: 1rem;
  height: auto; /* Set height to auto for flexibility */
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px); /* Frosted glass effect */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Border for glass effect */
  text-align: center; /* Center content for small screens */
}

.testimonial-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  flex-direction: column; /* Stack elements for smaller devices */
}

.review-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* Center content for small screens */
}

.text {
  font-size: 1rem;
  font-family: 'var(--primary-font)';
  color: #fff;
  /* background-color: rgba(0, 0, 0, 0.2); Slight background to make text readable */
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  width: 100%; /* Full width for responsive layout */
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.rating {
  display: flex;
  gap: 0.25rem;
}

.star {
  color: var(--trinary-color); /* Gold color for stars */
}

.user {
  font-size: 1rem;
  font-weight: 600;
  color: var(--secondary-color);
}

/* Glassmorphism effect for testimonial slides */
.splide__slide::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    rgba(148, 184, 237, 0.4),
    rgba(214, 168, 93, 0.4)
  );
  filter: blur(20px);
  z-index: 0;
  opacity: 0.6;
}

.splide__slide {
  position: relative;
  z-index: 1;
}

/* Responsive Media Queries */

/* Small screens (mobile devices) */
@media (max-width: 600px) {
  .splide__slide {
    padding: 1rem; /* Smaller padding for mobile */
  }

  .review-img {
    width: 60px;
    height: 60px; /* Smaller image size for mobile */
  }

  .text {
    font-size: 0.875rem; /* Smaller text for mobile */
    padding: 0.75rem;
  }

  .user {
    font-size: 0.875rem; /* Adjust font size for mobile */
  }

  .rating {
    gap: 0.15rem; /* Decrease gap between stars */
  }
}

/* Medium screens (tablets) */
@media (min-width: 600px) and (max-width: 1024px) {
  .splide__slide {
    padding: 1.5rem; /* Adjust padding for tablet */
  }

  .review-img {
    width: 70px;
    height: 70px; /* Adjust image size for tablet */
  }

  .text {
    font-size: 1rem; /* Standard font size */
    padding: 1rem;
  }

  .user {
    font-size: 1rem;
  }

  .rating {
    gap: 0.2rem; /* Adjust gap for tablet */
  }
}

/* Large screens (desktops) */
@media (min-width: 1024px) {
  .splide__slide {
    flex-direction: row; /* Keep elements side by side for larger screens */
    padding: 2rem 1.875rem;
  }

  .testimonial-slide {
    flex-direction: row; /* Keep horizontal layout on large screens */
  }

  .review-img {
    width: 80px;
    height: 80px;
  }

  .text {
    font-size: 1.125rem; /* Larger text for desktop */
    padding: 1.25rem;
  }

  .user {
    font-size: 1.125rem; /* Adjust user name font size */
  }
}

.slider-container .content .text {
  color: #372B29;
  margin-bottom: 1rem;
  font-size: 16px;
}

.slider-container .content .rating {
  display: inline-block;
  line-height: 1;
}

.slider-container .content .star {
  color: #DF9573;
  font-size: 1.1rem;
}

.slider-container .content .user {
  font-weight: 600;
}

.splide__pagination__page.is-active {
  background-color: #DF9573;
}

.splide__arrow {
  position: absolute;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  color: #505050;
  font-weight: 300;
}

.splide__arrow--prev {
  top: 50%;
  left: -3.5rem;
}

.splide__arrow--next {
  top: 50%;
  right: -3.5rem;
}

.splide__pagination__page {
  height: 10px;
  width: 10px;
  opacity: 1;
}

/* Media Query */
@media (max-width: 600px) {
  .testimonial-container{
    margin-bottom:150px;
  }
  .slider-container {
    height: 66vh; 
  }
  .splide {
    height: 100%;
  }
  .splide__slide {
    display: block;
    text-align: center;
    height: 100%;
    padding: 20px 30px;
  }

  .splide__arrow {
    font-size: 1.0rem;
  }

  .splide__arrow--prev {
    left: 0.2rem;
  }

  .splide__arrow--next {
    right: 0.2rem;
  }
  .splide__track {
    margin-bottom: 10px;
    height: 100%;
  }
  .splide__pagination{
    bottom: 18px;
    margin-bottom: 20px;
  }
  .slider-container .bottom-quote {
    right: 0;
  }
  .top-quote{
    height:50px;
    margin-top:-20px;
    margin-left:20px;
  }
  .bottom-quote{
    height:50px;
    margin-left:20px;
    /* top:440px */
  }
  .slider-container .review-img {
    width: 100px;
  height: 100px;

  }
}