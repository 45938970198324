.swiper-button-prev:after{
    font-size: 20px !important; 
  }
.swiper-button-next:after{
    font-size: 20px !important; 
  }

@media(max-width:700px){
  .swiper-button-prev:after{
    font-size: 15px !important; 
  }
.swiper-button-next:after{
    font-size: 15px !important; 
  }
}
  